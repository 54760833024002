import { faDiscord } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getVnasConfigurationAsync } from "@vatsim-vnas/js-libs/api/vnas";
import { Environment } from "@vatsim-vnas/js-libs/models/vnas";

import React, { useEffect, useState } from "react";
import Status from "src/components/Status";
import * as S from "src/styles/Styles";

function Home() {
  const [environments, setEnvironments] = useState<Environment[]>([]);

  useEffect(() => {
    (async () => {
      const res = await getVnasConfigurationAsync();
      if (res.ok) {
        setEnvironments(res.data!.environments);
      }
    })();
  }, []);

  return (
    <>
      <S.Row>
        <S.Card>
          <S.CardTitle>Welcome to vNAS!</S.CardTitle>
          <S.CardBody>
            <p>
              vNAS (short for Virtual National Airspace System) serves as the backbone for the next generation of high-fidelity air traffic
              controlling clients that allow controllers to work American airspace on the{" "}
              <a href="https://www.vatsim.net/" target="_blank" rel="noreferrer">
                VATSIM network
              </a>
              . The system interfaces directly with VATSIM architecture to import traffic data and facilitate international coordination.
              Additionally, vNAS hosts a repository of flightplans for aircraft transiting American airspace, manages each ARTCC&apos;s virtual
              computer, and synchronizes data for the hundreds of ATC facilities across the country.
            </p>
            <p>This site contains downloads, links, documentation, and changelogs for the many systems that interface with vNAS.</p>
            <p>
              <i>vNAS is in no way affiliated with the FAA.</i>
            </p>
          </S.CardBody>
        </S.Card>
        <S.Col>
          <S.ButtonLink href="https://discord.gg/MFtQbd9Svs" target="_blank" rel="noreferrer" $type="discord">
            <FontAwesomeIcon icon={faDiscord} /> <span>Join us on Discord</span>
          </S.ButtonLink>
          <S.Card>
            <S.CardSubtitle>vNAS Status</S.CardSubtitle>
            <S.CardBody>
              {environments.map((e) => (
                <Status environment={e} key={e.name} />
              ))}
            </S.CardBody>
          </S.Card>
        </S.Col>
      </S.Row>
      <S.Row>
        <S.ClientBox to="/crc">
          <S.ClientBoxBackgroundImage $url="/img/crc/eram1.png" $darken />
          <S.ClientBoxLabel>CRC</S.ClientBoxLabel>
        </S.ClientBox>
        <S.ClientBox to="/vstrips">
          <S.ClientBoxBackgroundImage $url="/img/vstrips/vstrips-close.png" $darken />
          <S.ClientBoxLabel>vStrips</S.ClientBoxLabel>
        </S.ClientBox>
        <S.ClientBox to="/vtdls">
          <S.ClientBoxBackgroundImage $url="/img/vtdls/vtdls-close.png" style={{ backgroundPosition: "left" }} $darken />
          <S.ClientBoxLabel>vTDLS</S.ClientBoxLabel>
        </S.ClientBox>
        <S.ClientBox to="/data-admin">
          <S.ClientBoxBackgroundImage $url="/img/data-admin/data-admin-close.png" $darken />
          <S.ClientBoxLabel>Data Admin</S.ClientBoxLabel>
        </S.ClientBox>
        <S.ClientBox to="/atctrainer">
          <S.ClientBoxBackgroundImage $url="/img/atctrainer/atctrainer-main.png" $darken />
          <S.ClientBoxLabel style={{ fontSize: "0.8em" }}>ATCTrainer</S.ClientBoxLabel>
        </S.ClientBox>
      </S.Row>
    </>
  );
}

export default Home;
