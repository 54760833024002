import React, { useState } from "react";
import * as S from "src/styles/Styles";

function Slideshow({ imageUrls }: { imageUrls: string[] }) {
  const [currentSlide, setCurrentSlide] = useState(0);

  return (
    <>
      <S.Slideshow>
        {imageUrls.map((u, i) => (
          <S.Slide $backgroundImageUrl={u} key={u} $isActive={currentSlide === i} href={u} target="_blank" />
        ))}
        {imageUrls.length > 1 && (
          <>
            <S.SlideshowPrevious onClick={() => setCurrentSlide((p) => (p === 0 ? imageUrls.length : p) - 1)}>&#10094;</S.SlideshowPrevious>
            <S.SlideshowNext onClick={() => setCurrentSlide((p) => (p + 1) % imageUrls.length)}>&#10095;</S.SlideshowNext>
          </>
        )}
      </S.Slideshow>
      {imageUrls.length > 1 && (
        <S.SlideshowDotBar>
          {imageUrls.map((u, i) => (
            <S.SlideDot $isActive={currentSlide === i} key={u} onClick={() => setCurrentSlide(i)} />
          ))}
        </S.SlideshowDotBar>
      )}
    </>
  );
}

export default Slideshow;
