import { faBook, faRocket } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Changelog, Slideshow } from "src/components";
import { PUBLIC_URL } from "src/constants";
import * as S from "src/styles/Styles";

function Vstrips() {
  return (
    <>
      <S.Row>
        <S.Card>
          <S.CardTitle>
            <S.CardTitleImage src="/img/vstrips/vstrips-logo.png" />
            vStrips
          </S.CardTitle>
          <S.CardBody>
            <p>
              vStrips is a web-based simulation of the paper flight progress strips used by FAA controllers in air traffic control towers. The
              application is used alongside a primary controlling client, such as CRC. Controllers logged in to the same vStrips facility share a set
              of flight strip bays that automatically update whenever a controller adds, moves, edits, or deletes a flight strip.
            </p>
            <p>
              vStrips also offers a variety of features that mirror realistic workflows, including the ability to add separators between flight
              strips, create custom half-strips for temporary or VFR flight plans, and offset strips to the side of a rack.
            </p>
            <p>vStrips is available on any web browser, though does not currently support touch-based input.</p>
          </S.CardBody>
        </S.Card>
        <S.Col>
          <S.ButtonLink $type="success" href="https://strips.virtualnas.net">
            <FontAwesomeIcon icon={faRocket} /> <span>Launch</span>
          </S.ButtonLink>
          <S.ButtonLink $type="primary" href="https://strips.virtualnas.net/docs">
            <FontAwesomeIcon icon={faBook} />
            <span>Documentation</span>
          </S.ButtonLink>
          <Slideshow imageUrls={["/img/vstrips/vstrips.png"]} />
        </S.Col>
      </S.Row>
      <S.Row>
        <Changelog changelogUrl={`${PUBLIC_URL}/changelogs/vstrips.json`} />
      </S.Row>
    </>
  );
}
export default Vstrips;
