import { faBook, faRocket } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Changelog, Slideshow } from "src/components";
import { PUBLIC_URL } from "src/constants";
import * as S from "src/styles/Styles";

function Vtdls() {
  return (
    <>
      <S.Row>
        <S.Card>
          <S.CardTitle>
            <S.CardTitleImage src="/img/vtdls/vtdls-logo.png" />
            vTDLS
          </S.CardTitle>
          <S.CardBody>
            <p>
              vTDLS is a high-fidelity simulation of the real-life FAA TDLS (Tower Data-Link Services) system that allows controllers to send
              pre-departure clearances (PDCs) to aircraft. The application is used alongside a primary vNAS controlling client, such as CRC. vTDLS
              greatly reduces the workload of clearance-delivery controllers at equipped airports, particularly during departure-heavy events as it
              allows controllers to review flight plans and send PDCs to pilots before they even connect to the network.
            </p>
            <p>vTDLS is available on any web browser.</p>
          </S.CardBody>
        </S.Card>
        <S.Col>
          <S.ButtonLink $type="success" href="https://tdls.virtualnas.net">
            <FontAwesomeIcon icon={faRocket} /> <span>Launch</span>
          </S.ButtonLink>

          <S.ButtonLink $type="primary" href="https://tdls.virtualnas.net/docs">
            <FontAwesomeIcon icon={faBook} />
            <span>Documentation</span>
          </S.ButtonLink>
          <Slideshow imageUrls={["/img/vtdls/vtdls.png"]} />
        </S.Col>
      </S.Row>
      <S.Row>
        <Changelog changelogUrl={`${PUBLIC_URL}/changelogs/vtdls.json`} />
      </S.Row>
    </>
  );
}
export default Vtdls;
