import { faBook, faDownload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getCrcConfigurationAsync } from "@vatsim-vnas/js-libs/api/vnas";
import React, { useEffect, useState } from "react";
import { Changelog, Slideshow } from "src/components";
import { PUBLIC_URL } from "src/constants";
import * as S from "src/styles/Styles";

function Crc() {
  const [crcDownloadUrl, setCrcDownloadUrl] = useState<string | undefined>();

  useEffect(() => {
    (async () => {
      const res = await getCrcConfigurationAsync();
      if (res.ok) {
        setCrcDownloadUrl(res.data!.installerUrl);
      }
    })();
  }, []);

  return (
    <>
      <S.Row>
        <S.Card>
          <S.CardTitle>
            <S.CardTitleImage src="/img/crc/crc-logo.png" />
            CRC
          </S.CardTitle>
          <S.CardBody>
            <p>
              CRC, or the Consolidated Radar Client, is the primary application used by controllers to connect to the VATSIM network and control an
              ATC position through vNAS. CRC is a highly customizable and multifaceted tool that can be used for controlling a wide range of positions
              from clearance delivery in an air traffic control tower&apos;s cab to an en route R-side position at an air route traffic control
              center.
            </p>
            <p>Included in CRC are the following display modes:</p>
            <ul>
              <li>Tower Cab - Used for simulating visual identification of aircraft from a control tower</li>
            </ul>
            <ul>
              <li>ASDE-X - A high-fidelity simulation of the real-world ground radar surveillance system</li>
            </ul>
            <ul>
              <li>STARS - A high-fidelity simulation of the real-world system used by approach control facilities across the United States</li>
            </ul>
            <ul>
              <li>ERAM - A high-fidelity simulation of the real-world system used by FAA en route controllers at ARTCCs</li>
            </ul>
            <p>CRC is available for Windows only.</p>
          </S.CardBody>
        </S.Card>
        <S.Col>
          <S.ButtonLink $type="success" href={crcDownloadUrl}>
            <FontAwesomeIcon icon={faDownload} />
            <span>Download</span>
          </S.ButtonLink>
          <S.ButtonLink $type="primary" href="https://crc.virtualnas.net/docs">
            <FontAwesomeIcon icon={faBook} />
            <span>Documentation</span>
          </S.ButtonLink>
          <Slideshow
            imageUrls={[
              "/img/crc/towercab1.png",
              "/img/crc/towercab2.png",
              "/img/crc/asdex1.png",
              "/img/crc/asdex2.png",
              "/img/crc/asdex3.png",
              "/img/crc/stars.png",
              "/img/crc/eram1.png",
              "/img/crc/eram2.png",
              "/img/crc/eram3.png",
            ]}
          />
        </S.Col>
      </S.Row>
      <S.Row>
        <Changelog changelogUrl={`${PUBLIC_URL}/changelogs/crc.json`} />
      </S.Row>
    </>
  );
}
export default Crc;
